import React, {Component} from "react";
import {connect} from "react-redux";
import ContestInFacesPageComponent from "./ContestInFacesPageComponent";
import {getSectionInfoByIdRequest} from '../../actions/documentsActions';

class DocumentsPageContainer extends Component {

    componentDidMount() {
        // TODO create server side contest_in_faces
        // this.props.getSectionInfoByIdRequest(this.props.match.params.competitionId, 'contest_in_faces')
        

        // this.props.fetchDocsRequest(code);
        // this.setState({docs: this.props.docs});
        
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log(999, this.props)
    // }
    //
    // openModalHandler(i) {
    //     console.log('i', i)
    // }

    render() {
        let fake_response = [
            {
                "id": 1,
                "name": "Конкурс глазами авторов лучших сочинений",
                "video": [],
                "documents": [
                    {
                        "name": "Абсолютные победители ВКС-2015",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2015.pdf",
                            "display_name": null,
                            "url": "/files/cif/533ead0d2f82c0098a54bd7f696f4766.pdf",
                            "size": 1381782,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    // {
                    //     "name": "ВКС-2015. Краснодарский край. Головко Никита",
                    //     "file": {
                    //         "realname": "ВКС-2015. Краснодарский край. Головко Никита",
                    //         "display_name": null,
                    //         "url": "https://disk.yandex.ru/i/umLMK_4Pd9hfog",
                    //         "size": null,
                    //         "thumbs": []
                    //     },
                    //     "uploads": null
                    // },
                    {
                        "name": "Абсолютные победители ВКС-2016",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2016.pdf",
                            "display_name": null,
                            "url": "/files/cif/dcb4ed968a85c806a6895b648860279b.pdf",
                            "size": 1786987,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Абсолютные победители ВКС-2017",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2017.pdf",
                            "display_name": null,
                            "url": "/files/cif/989348e942fd3b7170948c30679123a7.pdf",
                            "size": 1236991,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    // {
                    //     "name": "ВКС-2017. Самарская область. Кучукова Мария ВКС-2022. Самарская область. Самсонова Диана",
                    //     "file": {
                    //         "realname": "ВКС-2017. Самарская область. Кучукова Мария ВКС-2022. Самарская область. Самсонова Диана",
                    //         "display_name": null,
                    //         "url": "https://disk.yandex.ru/i/pEWn_XI3c8_ipQ",
                    //         "size": null,
                    //         "thumbs": []
                    //     },
                    //     "uploads": null
                    // },
                    // {
                    //     "name": "ВКС-2017. Победитель. Свердловская область. Суворин Олег",
                    //     "file": {
                    //         "realname": "ВКС-2017. Победитель. Свердловская область. Суворин Олег",
                    //         "display_name": null,
                    //         "url": "https://disk.yandex.ru/i/DDAk4mKK9gHsoA",
                    //         "size": null,
                    //         "thumbs": []
                    //     },
                    //     "uploads": null
                    // },
                    {
                        "name": "Абсолютные победители ВКС-2018",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2018.pdf",
                            "display_name": null,
                            "url": "/files/cif/973861c66cd7c150684626a370364a93.pdf",
                            "size": 761685,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    // {
                    //     "name": "ВКС-2018. Свердловская область. Можаев Борис",
                    //     "file": {
                    //         "realname": "ВКС-2018. Свердловская область. Можаев Борис",
                    //         "display_name": null,
                    //         "url": "https://disk.yandex.ru/i/rzo0e2CoWc1MGAA",
                    //         "size": null,
                    //         "thumbs": []
                    //     },
                    //     "uploads": null
                    // },
                    // {
                    //     "name": "ВКС-2018. Свердловская область. Пшеницына Софья",
                    //     "file": {
                    //         "realname": "ВКС-2018. Свердловская область. Пшеницына Софья",
                    //         "display_name": null,
                    //         "url": "https://disk.yandex.ru/i/sRXD_dspgT-AmA",
                    //         "size": null,
                    //         "thumbs": []
                    //     },
                    //     "uploads": null
                    // },
                    {
                        "name": "Абсолютные победители ВКС-2019",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2019.pdf",
                            "display_name": null,
                            "url": "/files/cif/83d609a8170a57391816dd6087d40195.pdf",
                            "size": 1223843,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Абсолютные победители ВКС-2020",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2020.pdf",
                            "display_name": null,
                            "url": "/files/cif/119142a2b0c0e09a3c0465f9f197aa0e.pdf",
                            "size": 1197708,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    // {
                    //     "name": "ВКС-2020. Волгоградская область. Болкунова Эвита",
                    //     "file": {
                    //         "realname": "ВКС-2020. Волгоградская область. Болкунова Эвита",
                    //         "display_name": null,
                    //         "url": "https://disk.yandex.ru/i/XCQcXNp-zBZ3DQ",
                    //         "size": null,
                    //         "thumbs": []
                    //     },
                    //     "uploads": null
                    // },
                    {
                        "name": "Абсолютные победители ВКС-2021",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2021.pdf",
                            "display_name": null,
                            "url": "/files/cif/a8ee6cfba1bea08248474ffb7486c55c.pdf",
                            "size": 787302,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Абсолютные победители ВКС-2022",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2022.pdf",
                            "display_name": null,
                            "url": "/files/cif/5a71adbdf60622e0d9781592dc45d4c3.pdf",
                            "size": 1169880,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    // {
                    //     "name": "ВКС-2022. Белгородская область. Теплов Даниил",
                    //     "file": {
                    //         "realname": "ВКС-2022. Белгородская область. Теплов Даниил",
                    //         "display_name": null,
                    //         "url": "https://disk.yandex.ru/i/LKuSTHkvvsO8Bw",
                    //         "size": null,
                    //         "thumbs": []
                    //     },
                    //     "uploads": null
                    // },
                    {
                        "name": "Абсолютные победители ВКС-2023",
                        "file": {
                            "realname": "Абсолютные победители ВКС-2023.pdf",
                            "display_name": null,
                            "url": "/files/cif/3cd7b842b0351611d4a728395270b161.pdf",
                            "size": 1142193,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Победители конкурса разных лет. Суворин О. Рыдова О. Кузьмина У.",
                        "file": {
                            "realname": "Победители конкурса разных лет. Суворин О. Рыдова О. Кузьмина У..pdf",
                            "display_name": null,
                            "url": "/files/cif/51ea6060cc3df0779dd6721271760294.pdf",
                            "size": 941653,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                ]
            },
            {
                "id": 2,
                "name": "Конкурс глазами членов жюри",
                "video": [],
                "documents": [
                    {
                        "name": "Костромская область. Абрамова С. Н. Красовская Ю.П.",
                        "file": {
                            "realname": "Костромская область. Абрамова С. Н. Красовская Ю.П..pdf",
                            "display_name": null,
                            "url": "/files/cif/794077a291373851b1cbe5d6c0b8b540.pdf",
                            "size": 450545,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Карачаево-Черкесская Республика. Абросимова Е.И.",
                        "file": {
                            "realname": "Карачаево-Черкесская Республика. Абросимова Е.И..pdf",
                            "display_name": null,
                            "url": "/files/cif/5d2c1e6a8bfb8a22a4b0d6902f15420f.pdf",
                            "size": 652557,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Рязанская область. Елуфимова Н.М. Радюшина С.Н.",
                        "file": {
                            "realname": "Рязанская область. Елуфимова Н.М. Радюшина С.Н..pdf",
                            "display_name": null,
                            "url": "/files/cif/7f6454fb9cab5c999d2c028f869fe911.pdf",
                            "size": 399105,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Иркутская область. Жюри регионального этапа",
                        "file": {
                            "realname": "Иркутская область. Жюри регионального этапа.pdf",
                            "display_name": null,
                            "url": "/files/cif/94649acee721e4c60b462150c26f01b4.pdf",
                            "size": 2137312,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                ]
            },
            {
                "id": 3,
                "name": "Конкурс глазами учителей-наставников",
                "video": [],
                "documents": [
                    {
                        "name": "Костромская область. Денисова Е.В.",
                        "file": {
                            "realname": "Костромская область. Денисова Е.В..pdf",
                            "display_name": null,
                            "url": "/files/cif/83b09b7b6f28914d4433001e86511a5c.pdf",
                            "size": 448337,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Свердловская область. Пипко Е.И. Кочубей Т.Д. Малявина А.М. Климкина С.В. Шустикова Н.В.",
                        "file": {
                            "realname": "Свердловская область. Пипко Е.И. Кочубей Т.Д. Малявина А.М. Климкина С.В. Шустикова Н.В.",
                            "display_name": null,
                            "url": "https://disk.yandex.ru/i/aoiX9xRxApBLqA",
                            "size": null,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Краснодарский край. Шкуркина Н.А.",
                        "file": {
                            "realname": "Краснодарский край. Шкуркина Н.А.",
                            "display_name": null,
                            "url": "https://disk.yandex.ru/i/Vtzyqz_i9M7RgA",
                            "size": null,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Самарская область. Рамзаева В.А.",
                        "file": {
                            "realname": "Самарская область. Рамзаева В.А.",
                            "display_name": null,
                            "url": "https://disk.yandex.ru/i/tPVlS5VrcfmVbQ",
                            "size": null,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                ]
            },
            {
                "id": 4,
                "name": "Конкурс глазами региональных координаторов",
                "video": [],
                "documents": [
                    {
                        "name": "Костромская область. Круглова Е.Н.",
                        "file": {
                            "realname": "Костромская область. Круглова Е.Н..pdf",
                            "display_name": null,
                            "url": "/files/cif/63378477efff6605c225f975309ae650.pdf",
                            "size": 470061,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Свердловская область. Смирнова Н.Л.",
                        "file": {
                            "realname": "Свердловская область. Смирнова Н.Л.",
                            "display_name": null,
                            "url": "https://disk.yandex.ru/i/zIEsny_siigh5A",
                            "size": null,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Красноярский край. Ильина Е.Б.",
                        "file": {
                            "realname": "Красноярский край. Ильина Е.Б..pdf",
                            "display_name": null,
                            "url": "/files/cif/d733a620f169f02fb26bc5fa43df90ba.pdf",
                            "size": 470105,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Самарская область. Ерофеева О.Ю.",
                        "file": {
                            "realname": "Самарская область. Ерофеева О.Ю.",
                            "display_name": null,
                            "url": "https://disk.yandex.ru/i/M6r9tu8hAaTM5Q",
                            "size": null,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                    {
                        "name": "Иркутская область. Митюкова Т.А.",
                        "file": {
                            "realname": "Иркутская область. Митюкова Т.А..pdf",
                            "display_name": null,
                            "url": "/files/cif/d733a620f169f02fb26bc5fa43dfffff.pdf",
                            "size": 400342,
                            "thumbs": []
                        },
                        "uploads": null
                    },
                ]
            }
        ];

        // fake_response = [];
        
        return <ContestInFacesPageComponent
            openModalHandler={this.props.openModalHandler}

            // TODO make a normal server response
            // THIS IS A FAKE SERVER RESPONSE
            // docs={this.props.documents}
            docs={fake_response}
            
            isDocsLoading={this.props.isDocsLoading}
        />;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openModalHandler: (url) => dispatch({type: "DOCS_OPEN_MODAL", payload: url}),
        getSectionInfoByIdRequest: (id, sectionBlock) =>
            dispatch(getSectionInfoByIdRequest(id, sectionBlock))
    };
};

const mapStateToProps = state => ({
    documents: state.documents.dokumenty,
    isDocsLoading: state.documents.isLoading,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsPageContainer);
