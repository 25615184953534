import photo1 from '../../assets/img/media2024/1_new_2024.jpg';
import photo2 from '../../assets/img/media2024/2_new_2024.jpg';
import photo3 from '../../assets/img/media2024/3_new_2024.jpg';
import photo4 from '../../assets/img/media2024/4_new_2024.jpg';
import photo5 from '../../assets/img/media2024/5_new_2024.jpg';
import photo6 from '../../assets/img/media2024/6_new_2024.jpg';
import photo7 from '../../assets/img/media2024/7_new_2024.jpg';
import photo8 from '../../assets/img/media2024/8_new_2024.jpg';
import photo9 from '../../assets/img/media2024/9_new_2024.jpg';
import photo10 from '../../assets/img/media2024/10_new_2024.jpg';
import photo11 from '../../assets/img/media2024/11_new_2024.jpg';
import photo12 from '../../assets/img/media2024/12_new_2024.jpg';
import photo13 from '../../assets/img/media2024/13_new_2024.jpg';
import photo14 from '../../assets/img/media2024/14_new_2024.jpg';
import photo15 from '../../assets/img/media2024/15_new_2024.jpg';
import photo16 from '../../assets/img/media2024/16_new_2024.jpg';
import photo17 from '../../assets/img/media2024/17_new_2024.jpg';
import photo18 from '../../assets/img/media2024/18_new_2024.jpg';
import photo19 from '../../assets/img/media2024/19_new_2024.jpg';
import photo20 from '../../assets/img/media2024/20_new_2024.jpg';
import photo21 from '../../assets/img/media2024/21_new_2024.jpg';
import photo22 from '../../assets/img/media2024/22_new_2024.jpg';
import photo23 from '../../assets/img/media2024/23_new_2024.jpg';
import photo24 from '../../assets/img/media2024/24_new_2024.jpg';
import photo25 from '../../assets/img/media2024/25_new_2024.jpg';
import photo26 from '../../assets/img/media2024/26_new_2024.jpg';
import photo27 from '../../assets/img/media2024/27_new_2024.jpg';
import photo28 from '../../assets/img/media2024/28_new_2024.jpg';
import photo29 from '../../assets/img/media2024/29_new_2024.jpg';
import photo30 from '../../assets/img/media2024/30_new_2024.jpg';
import photo31 from '../../assets/img/media2024/31_new_2024.jpg';
import photo32 from '../../assets/img/media2024/32_new_2024.jpg';
import photo33 from '../../assets/img/media2024/33_new_2024.jpg';
import photo34 from '../../assets/img/media2024/34_new_2024.jpg';
import photo35 from '../../assets/img/media2024/35_new_2024.jpg';
import photo36 from '../../assets/img/media2024/36_new_2024.jpg';
import photo37 from '../../assets/img/media2024/37_new_2024.jpg';
import photo38 from '../../assets/img/media2024/38_new_2024.jpg';
import photo39 from '../../assets/img/media2024/39_new_2024.jpg';
import photo40 from '../../assets/img/media2024/40_new_2024.jpg';
import photo41 from '../../assets/img/media2024/41_new_2024.jpg';
import photo42 from '../../assets/img/media2024/42_new_2024.jpg';
import photo43 from '../../assets/img/media2024/43_new_2024.jpg';
import photo44 from '../../assets/img/media2024/44_new_2024.jpg';
import photo45 from '../../assets/img/media2024/45_new_2024.jpg';
import photo46 from '../../assets/img/media2024/46_new_2024.jpg';
import photo47 from '../../assets/img/media2024/47_new_2024.jpg';
import photo48 from '../../assets/img/media2024/48_new_2024.jpg';
import photo49 from '../../assets/img/media2024/49_new_2024.jpg';
import photo50 from '../../assets/img/media2024/50_new_2024.jpg';
import photo51 from '../../assets/img/media2024/51_new_2024.jpg';
import photo52 from '../../assets/img/media2024/52_new_2024.jpg';
import photo53 from '../../assets/img/media2024/53_new_2024.jpg';
import photo54 from '../../assets/img/media2024/54_new_2024.jpg';
import photo55 from '../../assets/img/media2024/55_new_2024.jpg';
import photo56 from '../../assets/img/media2024/56_new_2024.jpg';
import photo57 from '../../assets/img/media2024/57_new_2024.jpg';
import photo58 from '../../assets/img/media2024/58_new_2024.jpg';
import photo59 from '../../assets/img/media2024/59_new_2024.jpg';
import photo60 from '../../assets/img/media2024/60_new_2024.jpg';
import photo61 from '../../assets/img/media2024/61_new_2024.jpg';
import photo62 from '../../assets/img/media2024/62_new_2024.jpg';
import photo63 from '../../assets/img/media2024/63_new_2024.jpg';
import photo64 from '../../assets/img/media2024/64_new_2024.jpg';
import photo65 from '../../assets/img/media2024/65_new_2024.jpg';
import photo66 from '../../assets/img/media2024/66_new_2024.jpg';
import photo67 from '../../assets/img/media2024/67_new_2024.jpg';
import photo68 from '../../assets/img/media2024/68_new_2024.jpg';
import photo69 from '../../assets/img/media2024/69_new_2024.jpg';
import photo70 from '../../assets/img/media2024/70_new_2024.jpg';
import photo71 from '../../assets/img/media2024/71_new_2024.jpg';
import photo72 from '../../assets/img/media2024/72_new_2024.jpg';
import photo73 from '../../assets/img/media2024/73_new_2024.jpg';
import photo74 from '../../assets/img/media2024/74_new_2024.jpg';
import photo75 from '../../assets/img/media2024/75_new_2024.jpg';
import photo76 from '../../assets/img/media2024/76_new_2024.jpg';
import photo77 from '../../assets/img/media2024/77_new_2024.jpg';
import photo78 from '../../assets/img/media2024/78_new_2024.jpg';
import photo79 from '../../assets/img/media2024/79_new_2024.jpg';
import photo80 from '../../assets/img/media2024/80_new_2024.jpg';
import photo81 from '../../assets/img/media2024/81_new_2024.jpg';
import photo82 from '../../assets/img/media2024/82_new_2024.jpg';
import photo83 from '../../assets/img/media2024/83_new_2024.jpg';
import photo84 from '../../assets/img/media2024/84_new_2024.jpg';
import photo85 from '../../assets/img/media2024/85_new_2024.jpg';
import photo86 from '../../assets/img/media2024/86_new_2024.jpg';
import photo87 from '../../assets/img/media2024/87_new_2024.jpg';
import photo88 from '../../assets/img/media2024/88_new_2024.jpg';
import photo89 from '../../assets/img/media2024/89_new_2024.jpg';
import photo90 from '../../assets/img/media2024/90_new_2024.jpg';
import photo91 from '../../assets/img/media2024/91_new_2024.jpg';
import photo92 from '../../assets/img/media2024/92_new_2024.jpg';
import photo93 from '../../assets/img/media2024/93_new_2024.jpg';
import photo94 from '../../assets/img/media2024/94_new_2024.jpg';
import photo95 from '../../assets/img/media2024/95_new_2024.jpg';
import photo96 from '../../assets/img/media2024/96_new_2024.jpg';
import photo97 from '../../assets/img/media2024/97_new_2024.jpg';
import photo98 from '../../assets/img/media2024/98_new_2024.jpg';
import photo99 from '../../assets/img/media2024/99_new_2024.jpg';
import photo100 from '../../assets/img/media2024/100_new_2024.jpg';
import photo101 from '../../assets/img/media2024/101_new_2024.jpg';
import photo102 from '../../assets/img/media2024/102_new_2024.jpg';
import photo103 from '../../assets/img/media2024/103_new_2024.jpg';
import photo104 from '../../assets/img/media2024/104_new_2024.jpg';
import photo105 from '../../assets/img/media2024/105_new_2024.jpg';
import photo106 from '../../assets/img/media2024/106_new_2024.jpg';
import photo107 from '../../assets/img/media2024/107_new_2024.jpg';
import photo108 from '../../assets/img/media2024/108_new_2024.jpg';
import photo109 from '../../assets/img/media2024/109_new_2024.jpg';
import photo110 from '../../assets/img/media2024/110_new_2024.jpg';
import photo111 from '../../assets/img/media2024/111_new_2024.jpg';
import photo112 from '../../assets/img/media2024/112_new_2024.jpg';


const photos2024 = [
    {
        id: 1,
        url: photo1
     },
     {
        id: 2,
        url: photo2
     },
     {
        id: 3,
        url: photo3
     },
     {
        id: 4,
        url: photo4
     },
     {
        id: 5,
        url: photo5
     },
     {
        id: 6,
        url: photo6
     },
     {
        id: 7,
        url: photo7
     },
     {
        id: 8,
        url: photo8
     },
     {
        id: 9,
        url: photo9
     },
     {
        id: 10,
        url: photo10
     },
     {
        id: 11,
        url: photo11
     },
     {
        id: 12,
        url: photo12
     },
     {
        id: 13,
        url: photo13
     },
     {
        id: 14,
        url: photo14
     },
     {
        id: 15,
        url: photo15
     },
     {
        id: 16,
        url: photo16
     },
     {
        id: 17,
        url: photo17
     },
     {
        id: 18,
        url: photo18
     },
     {
        id: 19,
        url: photo19
     },
     {
        id: 20,
        url: photo20
     },
     {
        id: 21,
        url: photo21
     },
     {
        id: 22,
        url: photo22
     },
     {
        id: 23,
        url: photo23
     },
     {
        id: 24,
        url: photo24
     },
     {
        id: 25,
        url: photo25
     },
     {
        id: 26,
        url: photo26
     },
     {
        id: 27,
        url: photo27
     },
     {
        id: 28,
        url: photo28
     },
     {
        id: 29,
        url: photo29
     },
     {
        id: 30,
        url: photo30
     },
     {
        id: 31,
        url: photo31
     },
     {
        id: 32,
        url: photo32
     },
     {
        id: 33,
        url: photo33
     },
     {
        id: 34,
        url: photo34
     },
     {
        id: 35,
        url: photo35
     },
     {
        id: 36,
        url: photo36
     },
     {
        id: 37,
        url: photo37
     },
     {
        id: 38,
        url: photo38
     },
     {
        id: 39,
        url: photo39
     },
     {
        id: 40,
        url: photo40
     },
     {
        id: 41,
        url: photo41
     },
     {
        id: 42,
        url: photo42
     },
     {
        id: 43,
        url: photo43
     },
     {
        id: 44,
        url: photo44
     },
     {
        id: 45,
        url: photo45
     },
     {
        id: 46,
        url: photo46
     },
     {
        id: 47,
        url: photo47
     },
     {
        id: 48,
        url: photo48
     },
     {
        id: 49,
        url: photo49
     },
     {
        id: 50,
        url: photo50
     },
     {
        id: 51,
        url: photo51
     },
     {
        id: 52,
        url: photo52
     },
     {
        id: 53,
        url: photo53
     },
     {
        id: 54,
        url: photo54
     },
     {
        id: 55,
        url: photo55
     },
     {
        id: 56,
        url: photo56
     },
     {
        id: 57,
        url: photo57
     },
     {
        id: 58,
        url: photo58
     },
     {
        id: 59,
        url: photo59
     },
     {
        id: 60,
        url: photo60
     },
     {
        id: 61,
        url: photo61
     },
     {
        id: 62,
        url: photo62
     },
     {
        id: 63,
        url: photo63
     },
     {
        id: 64,
        url: photo64
     },
     {
        id: 65,
        url: photo65
     },
     {
        id: 66,
        url: photo66
     },
     {
        id: 67,
        url: photo67
     },
     {
        id: 68,
        url: photo68
     },
     {
        id: 69,
        url: photo69
     },
     {
        id: 70,
        url: photo70
     },
     {
        id: 71,
        url: photo71
     },
     {
        id: 72,
        url: photo72
     },
     {
        id: 73,
        url: photo73
     },
     {
        id: 74,
        url: photo74
     },
     {
        id: 75,
        url: photo75
     },
     {
        id: 76,
        url: photo76
     },
     {
        id: 77,
        url: photo77
     },
     {
        id: 78,
        url: photo78
     },
     {
        id: 79,
        url: photo79
     },
     {
        id: 80,
        url: photo80
     },
     {
        id: 81,
        url: photo81
     },
     {
        id: 82,
        url: photo82
     },
     {
        id: 83,
        url: photo83
     },
     {
        id: 84,
        url: photo84
     },
     {
        id: 85,
        url: photo85
     },
     {
        id: 86,
        url: photo86
     },
     {
        id: 87,
        url: photo87
     },
     {
        id: 88,
        url: photo88
     },
     {
        id: 89,
        url: photo89
     },
     {
        id: 90,
        url: photo90
     },
     {
        id: 91,
        url: photo91
     },
     {
        id: 92,
        url: photo92
     },
     {
        id: 93,
        url: photo93
     },
     {
        id: 94,
        url: photo94
     },
     {
        id: 95,
        url: photo95
     },
     {
        id: 96,
        url: photo96
     },
     {
        id: 97,
        url: photo97
     },
     {
        id: 98,
        url: photo98
     },
     {
        id: 99,
        url: photo99
     },
     {
        id: 100,
        url: photo100
     },
     {
        id: 101,
        url: photo101
     },
     {
        id: 102,
        url: photo102
     },
     {
        id: 103,
        url: photo103
     },
     {
        id: 104,
        url: photo104
     },
     {
        id: 105,
        url: photo105
     },
     {
        id: 106,
        url: photo106
     },
     {
        id: 107,
        url: photo107
     },
     {
        id: 108,
        url: photo108
     },
     {
        id: 109,
        url: photo109
     },
     {
        id: 110,
        url: photo110
     },
     {
        id: 111,
        url: photo111
     },
     {
        id: 112,
        url: photo112
     }     
];

export default photos2024;