import React, { Component } from "react";
import styled from "styled-components";
import Select from "react-select";
import ReactPlayer from "react-player";
import { Col, Grid, Row } from "react-flexbox-grid";
import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { BreadcrumbsItemLink } from "../NewsPage/NewsPageComponent";
import { H2 } from "../../styles/fonts";
import { SectionTitle } from "../DocumentsPage/DocumentsPageComponent";
import { QAPageWrapper } from "../QAPage/QAPageContainer";
import Tab from "../WinnersPage/Tab";
import { getCurrentCards } from "../UI/PaginationStatic";
import PaginationStatic from "../UI/PaginationStatic";
import { DownLoadLink } from "../DocumentsPage/DocumentsPageComponent";
import { DownIcon } from "../WinnersPage/WinnerCard";
import { SelectWrapper } from "../WinnersPage/WinnersPageContainer";
import download from "../../assets/svg/down.svg";
import Popup from "../UI/Popup";
import ImageSlider from "./ImageSlider";
import VideoPopupContent from "./VideoPopupContent";
import webinarStub from "../../assets/img/foto_webinar.jpg";
import photos2018 from "./photos2018";
import photos2019 from "./photos2019";
import photos2021 from "./photos2021";
import photos2022 from "./photos2022";
import photos2023 from "./photos2023";
import photos2024 from "./photos2024";

const ImageContainer = styled(Col)`
  margin-top: 50px;
  //height: 250px;
  margin-bottom: 1%;
`;

const ImageWrapper = styled.div`
  max-width: 358px;
  width: 100%;
  //background: lightgrey;
  cursor: pointer;

  & > img {
    width: 100%;
  }
`;

export const Action = styled.div`
  font-family: "Roboto Condensed", sans-serif;
  line-height: 18px;

  & p {
    color: #3d4669;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    @media screen and (max-width: 580px) {
      padding: 0 15px;
    }
  }

  & a {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #6873b5;
    &:hover {
      cursor: pointer;
    }
  }
`;

const SelectWrapperYear = styled(SelectWrapper)`
  width: 155px;
  margin-left: 30px;
  margin-bottom: 0;

  @media screen and (max-width: 767px) {
    margin-top: 30px !important;
    margin-left: 0 !important;
  }

  & div.css-vj8t7z {
    height: 46px;
  }

  & div.css-2o5izw {
    height: 46px;
  }

  & div.css-11unzgr {
    max-height: 170px;
  }
`;

const PlayerWrapper = styled.div`
  width: 944px;
  height: 539px;
  margin: 50px auto 8px;

  @media screen and (max-width: 1000px) {
    width: 640px;
    height: 360px;
  }
  @media screen and (max-width: 640px) {
    height: 300px;
  }
  @media screen and (max-width: 375px) {
    width: 360px;
    height: 202px;
  }
`;

// const PlayerContainer = styled.div`
//   position:relative;
//   padding-bottom:56.25%;
//   padding-top:30px;
//   height:0;
//   overflow:hidden;
// `;
//
// const ReactPlayerStyled = styled(ReactPlayer)`
//   position:absolute;
//   top:0;
//   left:0;
//   width:100%;
//   height:100%;
// `;

const PlayerTitle = styled.div`
  margin: 10px auto 0;
  width: 944px;
  color: #3d4669;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.88px;

  @media screen and (max-width: 1000px) {
    width: 640px;
  }
  @media screen and (max-width: 375px) {
    width: 360px;
  }
`;

const videos2024 = [
  {
    id: "fab05347f338a1ffe5c295f6950d38b4",
    title: "Церемония награждения победителей ВКС",
    type: "rutube"
  },
  {
    id: "f57646959a2d0e59b16be5d8b7947494",
    title: "ВКС 10 лет.",
    type: "rutube"
  },
];

const videos2023 = [
  {
    id: "dc77e4fb05ae9a8a27a8aa680159c159",
    title: "Церемония награждения победителей Всероссийского конкурса сочинений ВКС-2023.",
    type: "rutube"
  },
];

const videos2022 = [
  {
    id: "uvlW8eAY9ts",
    title: "Церемония награждения победителей Всероссийского конкурса сочинений",
    type: "youtube"
  }
];

const videos2021 = [
  {
    id: "rWIZn8_0w0Q",
    title: "Всероссийский конкурс сочинений: как это было",
    type: "youtube"
  }
];

//const videos2019 = [
//  {
//    id: "PSjiCPBxSfI",
//    title:
//      "Установочный вебинар консультационно-методическое сопровождение ВКС в 2019 году"
//  }
//];

//const videos2018 = [
//  {
//    id: "c_V5SIo0XUQ",
//    title: "ВКС ролик Прямикова Е"
//  },
//  {
//    id: "SJD4_lSauog",
//    title: "ВКС ролик участников"
//  }
//];

const mainVideos = [
  //{
  //  url: "https://www.youtube.com/embed/zvBGncRr5mc?autoplay=1",
  //  id: "zvBGncRr5mc",
  //  year: "2019",
  //  title:
  //    "Видеообращение Жанны Витальевны Садовниковой, заместителя директора Департамента государственной политики в сфере общего образования Министерства просвещения Российской Федерации, к организаторам и участникам  Всероссийского конкурса сочинений - 2019"
  //},
  //{
  //  id: "nufzi7uBBq0",
  //  year: "2018",
  //  title:
  //    "Видеообращение Петрова Андря Евгеньевича, Директора Департамента государственной политики в сфере общего образования Министерства образования и науки Российской Федерации"
  //}

];

let optionsVideo = [
  {
    value: 2024,
    label: "2024"
  },
  {
    value: 2023,
    label: "2023"
  },
  {
    value: 2022,
    label: "2022"
  },
  {
    value: 2021,
    label: "2021"
  },
  //{
  //  value: 2020,
  //  label: "2020"
  //},
  //{
  //  value: 2019,
  //  label: "2019"
  //},
  //{
  //  value: 2018,
  //  label: "2018"
  //}
  // {
  //     value: 2017,
  //     label: "2017"
  // }
];

let optionsPhoto = [
  {
    value: 2024,
    label: "2024"
  },
  {
    value: 2023,
    label: "2023"
  },
  {
    value: 2022,
    label: "2022"
  },
  {
    value: 2021,
    label: "2021"
  },
  {
    value: 2020,
    label: "2020"
  },
  {
    value: 2019,
    label: "2019"
  },
  {
    value: 2018,
    label: "2018"
  }
];

class MediaPageComponent extends Component {
  state = {
    currentWinners: [],
    selectedTab: {
      value: 0,
      name: "видео"
    },
    search: "",
    currentPage: 1,
    cardsPerPage: 9,
    options: [],
    winners: [],
    selectedOption: { value: 2024, label: "2024" },
    // year: '2019',
    popupOpened: false,
    currentImgKey: 0,
    currentVideo: null,
    currentYearPhoto: photos2024
  };

  componentDidMount() {
    if (this.props.tab === 1) {
      this.setState({ selectedTab: { value: 1, name: "фото" } });
    } else {
      this.setState({ selectedTab: { value: 0, name: "видео" } });
      this.props.popupOpened && this.handleVideoOpen(mainVideos[0]);
    }
    // this.setState({ year: this.props.year});
    this.setState({
      //selectedOption: { value: this.props.year, label: `${this.props.year}` }
      selectedOption: { value: 2024, label: "2024" }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedTab.value === 1) {
      if (this.state.selectedOption.label !== prevState.selectedOption.label) {

        if (this.state.selectedOption.label === "2018") {
          this.setState({
            currentYearPhoto: photos2018
          });
        }

        if (this.state.selectedOption.label === "2019") {
          this.setState({
            currentYearPhoto: photos2019
          });
        }

        if (this.state.selectedOption.label === "2021") {
          this.setState({
            currentYearPhoto: photos2021
          });
        }

        if (this.state.selectedOption.label === "2022") {
          this.setState({
            currentYearPhoto: photos2022
          });
        }

        if (this.state.selectedOption.label === "2023") {
          this.setState({
            currentYearPhoto: photos2023
          });
        }

        if (this.state.selectedOption.label === "2024") {
          this.setState({
            currentYearPhoto: photos2024
          });
        }

      }
    }
  }

  isActive = value => {
    return value === this.state.selectedTab.value;
  };

  setActiveTab = selectedTab => {
    this.setState({ selectedTab });
    this.setState({ currentPage: 1 });
    if (selectedTab.value === 1) {
      this.setState({ selectedOption: optionsPhoto[0] });
      // this.setState({ year: '2018'});
    } else {
      this.setState({ selectedOption: optionsVideo[0] });
      // this.setState({ year: '2019'});
    }
  };

  handlePageClick = e => {
    this.setState({
      currentPage: Number(e.target.id)
    });
  };

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
    this.setState({ currentPage: 1 });
    // this.setState({ year: selectedOption.label }, () => {
    // });
  };

  handleSliderOpen = currentImgKey => {
    this.setState({ popupOpened: true });
    this.setState({ currentImgKey });
  };

  handleVideoOpen = currentVideo => {
    this.setState({ popupOpened: true });
    this.setState({ currentVideo });
  };

  handleClose(e) {
    const ESC = 27;
    if (e.keyCode === ESC) {
      this.setState({ popupOpened: false });
      this.setState({ currentVideo: null });
    }
  }

  render() {
    const {
      selectedTab,
      currentPage,
      cardsPerPage,
      selectedOption,
      popupOpened,
      currentImgKey,
      currentVideo
    } = this.state;

    const mainVideo = mainVideos.filter(
      video => video.year === selectedOption.label
    )[0];

    const photos =
      //this.state.currentYearPhoto;
      selectedOption.label === "2018"
        ? photos2018
        : selectedOption.label === "2019"
        ? photos2019
        : selectedOption.label === "2021"
        ? photos2021
        : selectedOption.label === "2022"
        ? photos2022
        : selectedOption.label === "2023"
        ? photos2023
        : selectedOption.label === "2024"
        ? photos2024
        : [];

    const videos =
      //selectedOption.label === "2019"
      //  ? videos2019
      //  : selectedOption.label === "2018"
      //  ? videos2018
      //  : [];
      selectedOption.label === "2024"
        ? videos2024
        : selectedOption.label === "2023"
        ? videos2023
        : selectedOption.label === "2022"
        ? videos2022
        : selectedOption.label === "2021"
        ? videos2021
        : [];

    const optionBase = selectedTab.value === 0 ? optionsVideo : optionsPhoto;

    const currentPhotos = getCurrentCards(currentPage, cardsPerPage, photos);
    const currentVideos = getCurrentCards(currentPage, cardsPerPage, videos);

    const Slider = (
      <ImageSlider images={currentPhotos} currentImgKey={currentImgKey} />
    );
    const Video = <VideoPopupContent video={currentVideo} />;

    return (
      <QAPageWrapper>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs
                separator={<span style={{ color: "#39404c" }}> / </span>}
                finalItem={"b"}
                finalProps={{
                  style: { color: "#6873b5", fontWeight: "normal" }
                }}
              />
              <BreadcrumbsItemLink to="/">Главная страница</BreadcrumbsItemLink>
              <BreadcrumbsItem to={`/news/?page=1`}>Медиатека</BreadcrumbsItem>
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <H2>Медиатека</H2>
            </Col>
          </Row>
          <Row style={{ marginBottom: "50px" }}>
            <Tab
              handleClick={this.setActiveTab.bind(this, {
                value: 0,
                name: "видео"
              })}
              tabName="видео"
              isActive={this.isActive(0)}
              value={0}
            />
            <Tab
              handleClick={this.setActiveTab.bind(this, {
                value: 1,
                name: "фото"
              })}
              tabName="фото"
              isActive={this.isActive(1)}
              value={0}
              borderRight="1px solid #b7c5d8"
            />
            <SelectWrapperYear>
              <Select
                options={optionBase}
                onChange={this.handleSelectChange}
                value={selectedOption}
              />
            </SelectWrapperYear>
          </Row>
          <Row>
            <SectionTitle color="#a8dbf5">
              {selectedTab.name} {selectedOption.value}
            </SectionTitle>
          </Row>
          {selectedTab.value === 0 && mainVideo !== undefined && (
            <Row>
              <PlayerWrapper>
                { mainVideo.type === "youtube" && (
                    <ReactPlayer
                        url={`https://www.youtube.com/embed/${mainVideo.id}`}
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                )}
              </PlayerWrapper>
              <PlayerTitle>{mainVideo.title}</PlayerTitle>
            </Row>
          )}
          <Row>
            {selectedTab.value === 1 &&
              currentPhotos.map((img, i) => (
                <ImageContainer key={i} lg={4} md={6}>
                  <ImageWrapper onClick={this.handleSliderOpen.bind(this, i)}>
                    <img src={img.url} alt="photo" />
                  </ImageWrapper>
                  <Action>
                    <DownLoadLink href={img.url} download>
                      <DownIcon src={download} />
                      Скачать
                    </DownLoadLink>
                  </Action>
                </ImageContainer>
              ))}
            {selectedTab.value === 0 &&
              currentVideos.length > 0 &&
              currentVideos.map((video, i) => (
                <ImageContainer key={i} lg={4} md={6}>
                  <ImageWrapper
                    onClick={this.handleVideoOpen.bind(this, video)}
                  >
                    { video.type === "youtube" && (
                        <img
                            src={`https://img.youtube.com/vi/${video.id}/hqdefault.jpg`}
                            alt="video"
                        />
                    )}
                    { video.type === "rutube" && (
                        <img
                            src={`https://rutube.ru/api/video/${video.id}/thumbnail/?redirect=1`}
                            alt="video"
                        />
                    )}
                  </ImageWrapper>
                  <Action>
                    <p>{video.title}</p>
                  </Action>
                </ImageContainer>
              ))}
          </Row>
          {selectedTab.value === 1 && (
            <PaginationStatic
              cards={photos}
              cardsPerPage={cardsPerPage}
              currentPage={currentPage}
              handleClick={this.handlePageClick}
            />
          )}
          {selectedTab.value === 0 && (
            <PaginationStatic
              cards={videos}
              cardsPerPage={cardsPerPage}
              currentPage={currentPage}
              handleClick={this.handlePageClick}
            />
          )}
          <br />
          {popupOpened && (
            <Popup
              handleClose={this.handleClose.bind(this)}
              children={currentVideo === null ? Slider : Video}
            />
          )}
        </Grid>
      </QAPageWrapper>
    );
  }
}

export default MediaPageComponent;
