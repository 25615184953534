import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-flexbox-grid";
import { PageContainer } from "../../styles/containers";
import { items } from '../../helpers/logosLinks';
import { createPath } from '../../helpers/createPath';

import main_logo from '../../assets/svg/logo-blue-transparent-site.svg';
import logo3 from "../../assets/img/logotyp_bw3.png";
import chevron from "../../assets/svg/right-thin-chevron.svg";
import vk from "../../assets/img/vk.png";
import logo_akadem from "../../assets/img/gupros.png";

const Footer = styled.div`
  width: 100%;
  background-color: #394c94;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 400;
`;

const LogoImg = styled.img`
  height: 107.281px;
  margin-bottom: 51px;
`;

const DropdownMenu = styled.ul`
  display: none;
  position: absolute;
  margin-top: -4px;
  width: 221px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 0 13px rgba(2, 2, 2, 0.35);
  list-style: none;
  background-color: rgba(40, 57, 116, 0.8);
  padding: 25px;
  z-index: 999;
  height: ${props => props.height};
        &::-webkit-scrollbar {
        width: 10px;
        background-color: #3d4e86;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(190, 204, 252, 0.6);
        outline: 1px solid #BECCFC;
        border-radius: 10px;
      }
`;

const DropdownItem = styled.li`
  margin-bottom: 10px;
  text-align: left;
  line-height: 24px;
  color: #beccfc;
  text-transform: none;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: #fff;
  }
`;

const Menu = styled.span`
  display: block;
  margin-bottom: 4px;
  line-height: 30px;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${props => props.color || `#fff`};
  }
`;

const DropContainer = styled.div`
  display: block;
  &:hover ${DropdownMenu} {
    display: block;
  }
`;

const Items = styled.span`
  display: block;
  margin-bottom: 7px;
  text-align: left;
  line-height: 24px;
  color: #beccfc;
  text-transform: none;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;

const FooterContactsNames = styled.div`
  margin-bottom: 19px;
  font-size: 22px;
  line-height: 18px;
  color: #fff;

  & div {
    margin-left: 0;
  }
`;

const FooterContacts = styled.div`
  margin-bottom: 41px;
  font-size: 24px;
  line-height: 18px;
  color: #ffffff;
  font-family: "Fira Sans Condensed";
  font-weight: 400;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none !important;
`;

const LogoText = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-family: "Fira Sans Condensed";
  font-weight: 400;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`;

const Icon = styled.img`
  margin-left: 10px;
  width: 13px;
  transform: rotate(90deg);
  cursor: pointer;
`;

const FooterComponent = ({competitions}) => {

  // const [years, setYears] = useState([])

  useEffect(() => {
    // const fetchData = async () => {
    //   const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/years-with-winners`)
    //   setYears(result.data.map(item=>item.name))
    // };
    // fetchData();
  }, [])

  // useEffect(() => {
  //   console.log('competitions', competitions.options)
  // }, [competitions])

  return (
    <Footer>
      <PageContainer>
        <Grid fluid>
          <Row>
            <Col lg={3} style={{ paddingTop: "15px" }}>
              <LogoImg src={main_logo} alt="logo" />
              <FooterContactsNames>
                <Row style={{ marginTop: "10px" }}>
                  <Col>+7 (495) 969-26-17</Col>
                  <div style={{fontSize: '15px', marginTop: '3px'}}>доб. 7502, 7526</div>
                </Row>
              </FooterContactsNames>
              <FooterContacts>
                <Row>
                  <Col lg={6}>vks.nov@guppros.ru</Col>
                </Row>
              </FooterContacts>
            </Col>
            <Col lg={1} />
            <Col lg={3} style={{ paddingTop: "50px" }}>
              <DropContainer>
                <Menu color="#fff" style={{ cursor: "auto" }}>
                  Конкурсы
                  <Icon src={chevron} alt="" />
                </Menu>
                <DropdownMenu>
                  {competitions.options && competitions.options.map((option, i) => (
                      <MenuLink
                          to={{
                            pathname: `/konkursi/${option['active-contest']}`
                          }}
                          key={i}
                      >
                        <DropdownItem>{option.name}</DropdownItem>
                      </MenuLink>
                  ))}
                </DropdownMenu>
              </DropContainer>
            </Col>
            <Col lg={2} style={{ paddingTop: "50px" }}>
              <Menu>материалы</Menu>
              <MenuLink to={`/docs/${createPath(competitions.options, competitions.info)}`}>
                <Items>Документы</Items>
              </MenuLink>
              {
                competitions && competitions.info && competitions.info.section &&
                items.filter(({id}) => competitions.info.section.map(section => section.id).includes(id)).map((item, i) => (
                    <MenuLink to={`${item.link}/${item.id}/${createPath(competitions.options, competitions.info)}`} key={i}>
                      <Items>{item.title}</Items>
                    </MenuLink>
                ))
              }
            </Col>
            <Col lg={3} style={{ position: "relative", paddingTop: "50px" }}>
              <Menu>
                <MenuLink to={`/winners?competitionId=${createPath(competitions.options, competitions.info)}`}>
                  Работы победителей
                </MenuLink>
              </Menu>
            </Col>
          </Row>
          <Row>
            <Col lg={12} style={{ paddingTop: "40px" }}>
              <Row>
                <Col lg={1}>
                  <LogoContainer>
                    {/*<img style={{ width: "294px" }} src={logo3} alt="logo" /> */}
                    {/*<LogoText>*/}
                    {/*  МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ*/}
                    {/*</LogoText>*/}
                  </LogoContainer>
                </Col>
                <Col lg={1}>
                  <LogoContainer style={{ marginTop: "-30px" }}>
                    <img style={{ height: "100px" }} src={logo_akadem} alt="logo" />
                  </LogoContainer>
                </Col>
                <Col lg={2}>
                </Col>
                <Col lg={4}>
                  <LogoContainer>
                    <img
                        style={{ height:"47.563px", marginRight: "3px" }}
                        src=""
                        alt=""
                     />
                    <LogoText>
                      © Государственный университет просвещения, 2024
                    </LogoText>
                  </LogoContainer>
                </Col>
                <Col lg={4}>
                  {/* <a href="https://vk.com/vks2019" target="_blank" rel="noopener noreferrer" > */}
                  {/*    <img src={vk} alt="" style={{ marginLeft: "108px", marginRight: "20px" }} /> */}
                  {/* </a> */}
                  <LogoContainer>
                    <img
                        style={{ height:"47.563px", marginRight: "3px" }}
                        src=""
                        alt=""
                     />
                    <LogoText>
                      v1.0.52
                    </LogoText>
                  </LogoContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </PageContainer>
    </Footer>
  );
};

export default FooterComponent;
